import React, { Fragment } from 'react';
import logo from './vscode.png';
import './App.scss';

// generate a type response from promise
interface TrialResponse extends Response {
    json(): Promise<{ message: string }>;
}

function App() {
    const [newKeyIsSend, setNewKeyIsSend] = React.useState<boolean>(false);
    const [emailValid, setEmailValid] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [message, setMessage] = React.useState<string>('');
    const emailRef = React.useRef<HTMLInputElement>(null);

    async function getNewKey(email: string): Promise<TrialResponse> {
        const randomMachineID = Math.floor(Math.random() * 9000000000) + 1000000000;
        const encodedUrl = encodeURIComponent(`https://api.devsense.com/license/?method=trial_vscode&machine_id=${randomMachineID}&mail=${email}`);
        return fetch(`https://corsproxy.io/?${encodedUrl}`);
    }

    const handleOnSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const email = formData.get('email')?.toString();
        if (!email) {
            return;
        }
        setIsLoading(true);
        try {
            let response = await getNewKey(email);
            console.log('🚀 ~ handleOnSubmit ~ response:', response);
            let data = await response.json();
            setMessage(data.message);
            setIsLoading(false);
            setNewKeyIsSend(true);
        } catch (error: Error | any) {
            setMessage(error.message);
            setIsLoading(false);
            setNewKeyIsSend(false);
        }
    };

    const handleOnChange = () => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValidEmail = emailPattern.test(emailRef.current?.value || '');
        setEmailValid(isValidEmail);
    };

    const handleRetry = (event: React.MouseEvent<HTMLButtonElement>) => {
        setNewKeyIsSend(false);
        setMessage('');
    };

    return (
        <div className="App">
            <header className="App-header">
                <a href="/" title="PHP Tools for Visual Studio Code">
                    <img src={logo} className="App-logo" alt="logo" />
                </a>
                <p>
                    Enter your E-Mail to get a new key for
                    <br />
                    <strong>PHP Tools for Visual Studio Code</strong>.
                </p>
                {message && (
                    <Fragment>
                        <h4 className="success">{message}</h4>
                        <button title="PHP Tools for Visual Studio Code" className="button" onClick={handleRetry}>
                            Retry
                        </button>
                    </Fragment>
                )}
                {!newKeyIsSend && (
                    <form onSubmit={handleOnSubmit} onChange={handleOnChange}>
                        <div className="form-group">
                            <input disabled={isLoading} ref={emailRef} type="email" name="email" id="email" required />
                            <button disabled={!emailValid || isLoading} type="submit">
                                Submit
                            </button>
                        </div>
                    </form>
                )}
            </header>
        </div>
    );
}

export default App;
